<template>
    <b-modal
      v-model="data.showModal"
      size="lg"
      button-size="sm"
      modal-class="template-modal"
      title-class="w-full"
      footer-class="w-full"
      no-fade
      @show="onShow"
      hide-header-close
      no-close-on-esc
      no-close-on-backdrop
      scrollable
    >
      <!-- Modal header -->
      <div slot="modal-title">
        <div class="flex gap-2">
          <span>Sample</span>
          <span v-if="sample">
            {{ sample.sample_number }}
          </span>
        </div>
      </div>

      <!-- Modal content -->
      <div class="flex flex-col gap-2">
        <div v-if="sample">
          <table class="table table-google">
            <tbody>
              <tr>
                <td class="row-fit">ID</td>
                <td>{{ sample.id | format}}</td>
              </tr>

              <tr>
                <td class="row-fit text-nowrap">Sample Number</td>
                <td>
                  <b-form-input
                    v-model="sample.sample_number"
                    autocomplete="off"
                    autofocus
                    size="sm"
                    trim
                  ></b-form-input>
                </td>
              </tr>

              <tr>
                <td class="row-fit text-nowrap">Sample Date</td>
                <td>
                  <date-picker
                    class="date-picker"
                    v-model="sample.sample_date"
                    format="DD.MM.YYYY"
                    valueType="YYYY-MM-DD"
                    :clearable="false"
                    :lang="datepickerLocale"
                  />
                </td>
              </tr>

              <tr>
                <td class="row-fit text-nowrap">Burn Cycles</td>
                <td>
                  <b-form-input
                    v-model="sample.burn_cycles"
                    type="number"
                    autocomplete="off"
                    size="sm"
                    trim
                  ></b-form-input>
                </td>
              </tr>

              <tr>
                <td class="row-fit text-nowrap">Fragrance + %</td>
                <td>

                  <div class="flex gap-2">
                    <v-select
                      v-model="sample.fragrance_id"
                      :options="dropdowns.fragrance"
                      size="sm"
                      label="text"
                      :reduce="option => option.value"
                      class="w-75"
                    />

                    <b-form-select
                      class="second-select"
                      v-model="sample.fragrance_pct_id"
                      :options="dropdowns.fragrance_pct"
                      size="sm"
                    ></b-form-select>
                  </div>
                </td>
              </tr>

              <tr>
                <td class="row-fit text-nowrap">Glass</td>
                <td>
                  <div class="flex gap-2">
                    <b-form-select
                      v-model="sample.glass_id"
                      :options="dropdowns.glass"
                      size="sm"
                      class="w-75"
                    ></b-form-select>

                    <b-form-select
                      class="second-select"
                      v-model="sample.glass_diameter_id"
                      :options="dropdowns.glass_diameter"
                      size="sm"
                    ></b-form-select>
                  </div>
                </td>
              </tr>

              <tr>
                <td class="row-fit text-nowrap">Wax</td>
                <td>
                  <b-form-select
                    v-model="sample.wax_id"
                    :options="dropdowns.wax"
                    size="sm"
                  ></b-form-select>
                </td>
              </tr>

              <tr>
                <td class="row-fit text-nowrap">Wick + Count</td>
                <td>
                  <div class="flex gap-2">
                    <v-select
                      v-model="sample.wick_id"
                      :options="dropdowns.wick"
                      size="sm"
                      label="text"
                      :reduce="option => option.value"
                      class="w-75"
                    />

                    <b-form-select
                      class="second-select"
                      v-model="sample.wick_count_id"
                      :options="dropdowns.wick_count"
                      size="sm"
                    ></b-form-select>
                  </div>
                </td>
              </tr>

              <tr>
                <td class="row-fit text-nowrap">Additive + %</td>
                <td>
                  <div class="flex gap-2">
                    <b-form-select
                      v-model="sample.additive_id"
                      :options="dropdowns.additive"
                      size="sm"
                      class="w-75"
                    ></b-form-select>

                    <b-form-select
                      class="second-select"
                      v-model="sample.additive_pct_id"
                      :options="dropdowns.additive_pct"
                      size="sm"
                    ></b-form-select>
                  </div>
                </td>
              </tr>

              <tr>
                <td>Status</td>
                <td>
                  <button-select
                    v-model="sample.sample_status"
                    :options="options.sample_status"
                    size="sm"
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <template v-for="cycle in sample.cycles">
            <table
              class="table table-google"
              :key="cycle.cycle_number"
            >
              <thead>
                <tr>
                  <th colspan="2">
                    <div class="flex justify-between">
                      <div>Cycle #{{ cycle.cycle_number }}</div>
                      <div>
                        <b-btn
                          v-if="cycle.cycle_number === sample.cycles.length"
                          variant="danger"
                          size="sm"
                          @click="deleteCycle(cycle)"
                        >
                          Delete cycle
                        </b-btn>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="row-fit text-nowrap">Date</td>
                  <td>
                    <date-picker
                      class="date-picker"
                      v-model="cycle.cycle_date"
                      format="DD.MM.YYYY HH:mm"
                      type="datetime"
                      valueType="YYYY-MM-DD HH:mm:ss"
                      :clearable="false"
                      :lang="datepickerLocale"
                    />
                  </td>
                </tr>

                <tr>
                  <td class="row-fit text-nowrap">Hour 1</td>
                  <td>
                    <div class="flex gap-2">
                      <b-form-select
                        v-model="cycle.h1_burn_fail_id"
                        :options="dropdowns.burn_fail"
                        size="sm"
                      ></b-form-select>

                      <b-input-group
                        class="second-select"
                        size="sm"
                        append="°C"
                      >
                        <b-form-input
                          v-model="cycle.h1_glass_temperature"
                          autocomplete="off"
                          size="sm"
                          trim
                          placeholder="Glass temp."
                        ></b-form-input>
                      </b-input-group>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td class="row-fit text-nowrap">Hour 2</td>
                  <td>
                    <div class="flex gap-2">
                      <b-form-select
                        v-model="cycle.h2_burn_fail_id"
                        :options="dropdowns.burn_fail"
                        size="sm"
                      ></b-form-select>

                      <b-input-group
                        class="second-select"
                        size="sm"
                        append="°C"
                      >
                        <b-form-input
                          v-model="cycle.h2_glass_temperature"
                          autocomplete="off"
                          size="sm"
                          trim
                          placeholder="Glass temp."
                        ></b-form-input>
                      </b-input-group>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td class="row-fit text-nowrap">Hour 3</td>
                  <td>
                    <div class="flex gap-2">
                      <b-form-select
                        v-model="cycle.h3_burn_fail_id"
                        :options="dropdowns.burn_fail"
                        size="sm"
                      ></b-form-select>

                      <b-input-group
                        class="second-select"
                        size="sm"
                        append="°C"
                      >
                        <b-form-input
                          v-model="cycle.h3_glass_temperature"
                          autocomplete="off"
                          size="sm"
                          trim
                          placeholder="Glass temp."
                        ></b-form-input>
                      </b-input-group>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td class="row-fit text-nowrap">Hour 4</td>
                  <td>
                    <div class="flex gap-2">
                      <b-form-select
                        v-model="cycle.h4_burn_fail_id"
                        :options="dropdowns.burn_fail"
                        size="sm"
                      ></b-form-select>

                      <b-input-group
                        class="second-select"
                        size="sm"
                        append="°C"
                      >
                        <b-form-input
                          v-model="cycle.h4_glass_temperature"
                          autocomplete="off"
                          size="sm"
                          trim
                          placeholder="Glass temp."
                        ></b-form-input>
                      </b-input-group>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td colspan="2">
                    <div class="grid grid-cols-4 gap-8">
                      <div>
                        <div>Before Burn Front</div>
                        <image-box :data="cycle" field="preburn_front_file_id" />
                      </div>

                      <div>
                        <div>Before Burn Top</div>
                        <image-box :data="cycle" field="preburn_top_file_id" />
                      </div>

                      <div>
                        <div>After Burn Front</div>
                        <image-box :data="cycle" field="postburn_front_file_id" />
                      </div>

                      <div>
                        <div>After Burn Top</div>
                        <image-box :data="cycle" field="postburn_top_file_id" />
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </template>

          <div class="flex flex-col gap-2">
            <div>Note</div>
            <div>
              <b-form-textarea
                v-model="sample.sample_note"
                rows="6"
                max-rows="12"
              ></b-form-textarea>
            </div>
          </div>
        </div>
        <div v-else-if="isLoading">
          Loading..
        </div>
        <div v-else>
          Error
        </div>

        <div ref="modal-end"></div>
      </div>

      <!-- Modal footer -->
      <div slot="modal-footer" class="w-full d-flex gap-4 justify-content-between items-center">
        <div class="flex w-full justify-content-between">
          <div class="flex gap-2">
            <b-btn
              size="sm"
              variant="primary"
              :disabled="isLoading || !canSave"
              @click="onSave"
            >
              Save
            </b-btn>

            <b-btn
              size="sm"
              variant="primary"
              :disabled="isLoading || !canStartCycle"
              @click="createCycle()"
            >
              Start new cycle
            </b-btn>
          </div>
          <div class="flex gap-2">
            <b-btn
              size="sm"
              variant="danger"
              :disabled="isLoading"
              @click="deleteSample()"
            >
              Delete
            </b-btn>

            <b-btn
              size="sm"
              variant="white"
              :disabled="isLoading"
              @click="onClose"
            >
              Close
            </b-btn>
          </div>
        </div>
      </div>
    </b-modal>
  </template>

<script>
import moment from 'moment';
import {
  datepickerLocale,
  stringToNumber,
  numberToString,
} from '@/helpers';

const ButtonSelect = () => import('@/components/global/ButtonSelect.vue');
const ImageBox = () => import('@/components/global/ImageBox.vue');

export default {
  name: 'SampleModal',
  components: {
    ButtonSelect,
    ImageBox,
  },
  props: {
    data: Object,
    options: Object,
  },
  computed: {
    stopStatus() {
      const stopIssue = this.dropdowns.burn_fail.find((issue) => issue.text === 'STOP');
      const stopId = stopIssue.value;
      if (!stopIssue) {
        return false;
      }
      for (let i = 0; i < this.sample.cycles.length; i++) {
        const cycle = this.sample.cycles[i];
        const hasStop = this.failFields.some((failField) => (cycle[failField] === stopId));
        if (hasStop) {
          return true;
        }
      }
      return false;
    },
    changes() {
      const changes = JSON.parse(JSON.stringify(this.sample));
      changes.cycles.forEach((cycle) => {
        this.temperatureFields.forEach((temperatureField) => {
          cycle[temperatureField] = stringToNumber(cycle[temperatureField]);
        });
      });
      changes.stop_status = this.stopStatus;
      return changes;
    },
    isLoading() {
      return this.loadingCount > 0;
    },
    canSave() {
      return true;
    },
    datepickerLocale() {
      return datepickerLocale;
    },
    canStartCycle() {
      if (!this.sample) return false;
      if (isNaN(this.sample.burn_cycles)) return false;
      return Number(this.sample.burn_cycles) > this.sample.cycles.length;
    },
    dropdowns() {
      return this.$store.state.app.dropdowns;
    },
  },
  data() {
    return {
      loadingCount: 0,
      filters: {},
      sample: null,
      temperatureFields: [
        'h1_glass_temperature',
        'h2_glass_temperature',
        'h3_glass_temperature',
        'h4_glass_temperature',
      ],
      failFields: [
        'h1_burn_fail_id',
        'h2_burn_fail_id',
        'h3_burn_fail_id',
        'h4_burn_fail_id',
      ],
    };
  },
  methods: {
    handleScroll() {
      if (!this.sample || this.sample.cycles.length === 0) {
        return;
      }
      this.$nextTick().then(() => {
        const ref = this.$refs['modal-end'];
        ref.scrollIntoView();
      });
    },
    fetchSample() {
      this.loadingCount++;
      this.$http
        .get(`/wick_sample/samples/${this.data.sampleId}`)
        .then((res) => {
          res.body.sample.cycles.forEach((cycle) => {
            this.temperatureFields.forEach((temperatureField) => {
              cycle[temperatureField] = numberToString(cycle[temperatureField]);
            });
          });
          this.sample = res.body.sample;
          this.handleScroll();
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch sample: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    createCycle() {
      const cycle = {
        id: null,
        cycle_number: this.sample.cycles.length + 1,
        cycle_date: moment().format('YYYY-MM-DD HH:mm:ss'),
        h1_burn_fail_id: null,
        h2_burn_fail_id: null,
        h3_burn_fail_id: null,
        h4_burn_fail_id: null,
        h1_glass_temperature: '',
        h2_glass_temperature: '',
        h3_glass_temperature: '',
        h4_glass_temperature: '',
        preburn_top_file_id: null,
        preburn_front_file_id: null,
        postburn_top_file_id: null,
        postburn_front_file_id: null,
      };
      this.sample.cycles.push(cycle);
      this.handleScroll();
    },
    deleteCycle(deleteCycle) {
      if (!confirm('Do you really wish to delete this cycle?')) {
        return;
      }
      this.sample.cycles = this.sample.cycles.filter(
        (cycle) => cycle.cycle_number !== deleteCycle.cycle_number,
      );
    },
    deleteSample() {
      if (!confirm(`Do you really wish to delete sample '${this.sample.sample_number}'?`)) {
        return;
      }
      this.loadingCount++;
      this.$http
        .delete(`/wick_sample/samples/${this.data.sampleId}`)
        .then(() => {
          this.$emit('deleted', this.sample);
          this.onClose();
        })
        .catch((err) => {
          this.$toast.error(`Failed to delete sample: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    updateSample() {
      this.loadingCount++;
      this.$http
        .put(`/wick_sample/samples/${this.data.sampleId}`)
        .send({ changes: this.changes })
        .then(() => {
          this.$emit('updated', this.sample);
          this.onClose();
        })
        .catch((err) => {
          this.$toast.error(`Failed to update sample: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchAll() {
      this.fetchSample();
    },
    onSave() {
      this.updateSample();
    },
    onShow() {
      this.fetchAll();
    },
    onClose() {
      this.data.showModal = false;
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
  .second-select {
    width: 150px;
  }
</style>
